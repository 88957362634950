<template>
  <div :class="classMessageType">
    <div
      class="message__bubble"
      :class="{ date: hideTimestamp, transfer: isTransfer }"
    >
      <div class="message__notification">
        <div
          v-if="showIcon"
          class="message__notification-icon"
          :class="classStatus()"
        >
          <component :is="iconComponent" />
        </div>
        <span :class="classTextContent" v-html="notificationText"></span>
        <div v-if="!hideTimestamp" class="message__notification-trail">
          {{ timestamp }}
        </div>
      </div>
    </div>
    <div
      class="message__notification-separator"
      :class="{ 'separator-blue': hideTimestamp || isTransfer }"
    />
  </div>
</template>

<script>

export default {
  props: {
    message: {
      required: true,
      type: Object,
    },
    hasNext: {
      default: false,
    },
  },
  computed: {
    classMessageType() {
      return [
        'message',
        'message--item',
        {
          'message--has-next': this.hasNext,
        },
      ];
    },
    classTextContent() {
      return [
        'message__notification-content',
        {
          'message__notification-content--uppercase': this.hasRelativeTimestamp,
          date: this.hideTimestamp,
        },
      ];
    },
    timestamp() {
      return this.globalFormatTime(this.message.createdAt);
    },
    notificationType() {
      return this.message.type;
    },
    isStatusChange() {
      return this.notificationType === 'statusChange';
    },
    statusChange() {
      return this.message.statusChange ?? {};
    },
    statusTo() {
      return this.statusChange.to ?? 0;
    },
    statusLabel() {
      return `<span style="color:${this.statusColor()};">${this.$t('chat.conversation-changed')}
      <b>${this.getStatusFromId(this.statusTo)}`;
    },
    isTransfer() {
      return this.notificationType === 'transfer';
    },
    transfer() {
      return this.message.transfer ?? {};
    },
    transferLabel() {
      // TODO implement
      return 'Conversación transferida';
    },
    showIcon() {
      return this.isStatusChange || this.isTransfer;
    },
    iconComponent() {
      if (this.isStatusChange) {
        return 'reload-icon';
      }

      if (this.isTransfer) {
        return 'shuffle-icon';
      }

      return '';
    },
    notificationText() {
      if (this.isStatusChange) {
        return this.statusLabel;
      }

      if (this.isTransfer) {
        return this.transferLabel;
      }

      if (this.hasRelativeTimestamp) {
        return this.globalFormatRelativeDateTime(this.message.relativeTimestamp, false, true);
      }

      return '';
    },
    hasRelativeTimestamp() {
      return !!this.message.relativeTimestamp;
    },
    hideTimestamp() {
      return !!this.message.hideTimestamp;
    },
  },
  methods: {
    getStatusFromId(id) {
      switch (id) {
        case 1:
          return this.$t('chat.no-assign');
        case 2:
          return this.$t('chat.assigned');
        case 3:
          return this.$t('chat.process');
        case 4:
          return this.$t('chat.resolved');
        case 5:
          return this.$t('chat.cancelled');
        case 6:
          return this.$t('chat.out-of-time');
        case 7:
          return this.$t('chat.disconnected-agents');
        case 8:
          return 'ICR';
        default:
          return '';
      }
    },
    classStatus() {
      switch (this.statusTo) {
        case 1:
          return 'not-assigned';
        case 2:
          return 'assigned';
        case 3:
          return 'in-progress';
        case 4:
          return 'resolved';
        case 5:
          return 'cancelled';
        case 6:
          return 'out-of-time';
        case 7:
          return 'disconnected-agents';
        case 8:
          return 'icr';
        default:
          return '';
      }
    },
    statusColor() {
      switch (this.statusTo) {
        case 1:
          return '';
        case 2:
          return '#cca300';
        case 3:
          return '#37ae5f';
        case 4:
          return '#020094';
        case 5:
          return '#c20000';
        case 6:
          return '#a9a6a9';
        case 7:
          return '#49657B';
        case 8:
          return '#03a9f4';
        default:
          return '';
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/chat/_message.scss";
@import "~styles/components/chat/message-status.scss";
</style>
